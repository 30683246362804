import {DateTime} from "luxon";

export default {
    name: "clipboard-table",
    props: {
        data: Array,
    },
    computed: {
        getData() {
            let data = this.data.map((item) => {
                let parsed_dt = DateTime.fromFormat(item.metadata.created_at, "EEE MMM dd HH:mm:ss ZZZ yyyy");
                if (!parsed_dt.invalid) item.metadata.created_at = parsed_dt.toFormat("dd MMM yyyy");
                return item;
            });
            return data;
        },
    },
    data() {
        return {};
    },
    mounted() {},
};
